import Speaker from '../Speaker'
import data from './speakers'

const Speakers = () => {
  const speakers = data.map(speaker => <Speaker key={speaker.name} speaker={speaker} />)

  return (
    <div className="sessions mb-4">
      <div className="card speakers">
        <div className="card-header bg-primary text-white text-center">
          <strong>Speakers</strong>
        </div>
        <ul className="list-group list-group-flush">{speakers}</ul>
      </div>
    </div>
  )
}

export default Speakers
