const Day = ({ day, sessions, timeZoneCity, timeZoneContinent, toggleTimeZone }) => {
  let timeZone = `Timezone: ${timeZoneCity}`

  if (timeZoneContinent) {
    timeZone += ` (${timeZoneContinent})`
  }

  return (
    <div className="sessions mb-4">
      <div className="card">
        <div className="card-header bg-primary text-white">
          <div className="text-md-center position-relative">
            <strong className="d-inline">
              <span className="text-nowrap">
                {day}
                <span className="d-none d-sm-inline"> Programme</span>
              </span>
            </strong>
            <span
              className="badge rounded-pill bg-light text-dark position-absolute top-50 end-0 translate-middle-y cursor-pointer"
              onClick={toggleTimeZone}
            >
              {timeZone}
            </span>
          </div>
        </div>
        <ul className="list-group list-group-flush">{sessions}</ul>
      </div>
    </div>
  )
}

export default Day
