import { useState } from 'react'
import programme from './programme.js'
import Session from '../Session/index.jsx'
import Day from '../Day/index.jsx'

const Sessions = () => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [timeZone, setTimeZone] = useState(userTimeZone)

  const saturdaySessions = programme.saturday.map(session => (
    <Session session={session} timeZone={timeZone} />
  ))

  const sundaySessions = programme.sunday.map(session => (
    <Session session={session} timeZone={timeZone} />
  ))

  let timeZoneContinent
  let timeZoneCity = timeZone

  if (timeZone.includes('/')) {
    timeZoneContinent = timeZone.split('/')[0]
    timeZoneCity = timeZone.split('/')[1].replace('_', ' ')
  }

  const toggleTimeZone = () => {
    console.log(timeZone)
    if (timeZone === userTimeZone) {
      setTimeZone('GMT')
    } else {
      setTimeZone(userTimeZone)
    }
  }

  return (
    <>
      <Day
        day="Saturday"
        sessions={saturdaySessions}
        timeZoneCity={timeZoneCity}
        timeZoneContinent={timeZoneContinent}
        toggleTimeZone={toggleTimeZone}
      />
      <Day
        day="Sunday"
        sessions={sundaySessions}
        timeZoneCity={timeZoneCity}
        timeZoneContinent={timeZoneContinent}
        toggleTimeZone={toggleTimeZone}
      />
    </>
  )
}

export default Sessions
