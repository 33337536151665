const calculateStartAndEndTimes = (startTime, durationInMinutes, timeZone) => {
  const date = new Date()

  const [hours, minutes] = startTime.split(':').map(Number)

  const startDateGMT = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes)
  )

  const startLocalTime = startDateGMT.toLocaleTimeString([], {
    timeZone,
    hour: '2-digit',
    minute: '2-digit',
  })

  startDateGMT.setMinutes(startDateGMT.getMinutes() + durationInMinutes)

  const localEndTime = startDateGMT.toLocaleTimeString([], {
    timeZone,
    hour: '2-digit',
    minute: '2-digit',
  })

  return { startLocalTime, localEndTime }
}

const Session = ({ session, timeZone }) => {
  const { title, speaker, startTime, duration, description } = session

  const { startLocalTime, localEndTime } = calculateStartAndEndTimes(startTime, duration, timeZone)

  return (
    <li className="list-group-item d-flex justify-content-between align-items-start">
      <div>
        <h5 className="mb-1">
          <div dangerouslySetInnerHTML={{ __html: title }} />
        </h5>
        <p className="mb-0 text-muted">
          <small>{speaker}</small>
          <span dangerouslySetInnerHTML={{ __html: description }} />
        </p>
      </div>
      <span className="badge bg-primary rounded-pill">
        {startLocalTime} – {localEndTime}
      </span>
    </li>
  )
}

export default Session
