import logo from './logo.png'
import Sessions from './components/Sessions/index.jsx'
import Links from './components/Links/index.jsx'
import Speakers from './components/Speakers/index.jsx'

const App = () => {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-auto">
          <div>
            <img
              src={logo}
              width={500}
              alt="Christopher Tolkien Centenary Conference"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-auto">
          <p className="lead text-center">
            Hosted online by The Tolkien Society, 23<sup>rd</sup>–24<sup>th</sup> November 2024
          </p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-auto">
          <div className="content-boxes">
            <Links />
            <Sessions />
            <Speakers />
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-auto text-center mb-4">
          <small>
            The Tolkien Society is a registered charity (registration no 273809) in England and
            Wales. Founded 1969.
          </small>
        </div>
      </div>
    </div>
  )
}

export default App
