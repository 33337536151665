const Speaker = ({ speaker }) => {
  const { name, description } = speaker

  return (
    <li className="list-group-item align-items-start">
      <div>
        <h5 className="mb-1">{name}</h5>
        <p className="mb-0 text-muted" dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </li>
  )
}

export default Speaker
