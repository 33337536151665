import Link from '../Link'

import {
  zoomWebinarSaturdayURL,
  zoomWebinarSundayURL,
  zoomBreakoutSaturdayURL,
  zoomBreakoutSundayURL,
} from '../../constants.js'

const data = [
  {
    title: 'Saturday Zoom Webinar',
    url: zoomWebinarSaturdayURL,
    icon: 'tv-fill',
  },
  {
    title: 'Saturday Zoom Breakout Rooms',
    url: zoomBreakoutSaturdayURL,
    icon: 'people-fill',
  },
  {
    title: 'Sunday Zoom Webinar',
    url: zoomWebinarSundayURL,
    icon: 'tv-fill',
  },
  {
    title: 'Sunday Zoom Breakout Rooms',
    url: zoomBreakoutSundayURL,
    icon: 'people-fill',
  },
  {
    title: 'Discord',
    url: 'https://discord.gg/VsTECbxSpT',
    icon: 'discord',
  },
  {
    title: 'Guestbook',
    url: 'https://www.replaymyday.com/event/cjrt100',
    icon: 'pencil-fill',
  },
  {
    title: 'Donate to The Tolkien Society',
    url: 'https://www.tolkiensociety.org/donate/',
    icon: 'heart-fill',
  },
]

const Links = () => {
  const links = data.map(link => <Link key={link.url} link={link} />)

  return (
    <div className="sessions mb-4">
      <div className="card links">
        <div className="card-header bg-primary text-white text-center">
          <strong>Essential Links</strong>
        </div>
        <ul className="list-group list-group-flush">{links}</ul>
      </div>
    </div>
  )
}

export default Links
