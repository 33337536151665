const speakers = [
  {
    name: 'Douglas A. Anderson',
    description: 'Editor of <em>The Annotated Hobbit</em>',
  },
  {
    name: 'Nicholas Birns',
    description: 'Author of <em>The Literary Role of History in the Fiction of J.R.R. Tolkien</em>',
  },
  {
    name: 'Denis Bridoux',
    description:
      'Independent Tolkien scholar specialising in Tolkien’s artwork and heraldic devices',
  },
  {
    name: 'Sara Brown',
    description:
      'Lecturer on Tolkien, and Language and Literature Department Chair at Signum University',
  },
  {
    name: 'Sonali Chunodkar',
    description: 'Researcher on secondary beliefs in Tolkien’s works',
  },
  {
    name: 'Michael D. C. Drout',
    description:
      'Editor of <em>Beowulf and the Critics</em>, and <em>J.R.R. Tolkien Encyclopedia</em>; co-editor of <em>Tolkien Studies</em>',
  },
  {
    name: 'Vincent Ferré',
    description:
      'Professor in Comparative Literature (University Sorbonne Nouvelle), translator, and editor of <em>Dictionnaire Tolkien</em>. Literary advisor to the Estate of Christopher Tolkien',
  },
  {
    name: 'Dimitra Fimi',
    description:
      'Tolkien scholar and fantasy professor at the University of Glasgow, co-editor of <em>A Secret Vice</em>, author of <em>Tolkien, Race and Cultural History</em>',
  },
  {
    name: 'Verlyn Flieger',
    description:
      'Editor of <em>Smith of Wootton Major</em>, <em>The Story of Kullervo</em>, and <em>The Lay of Aotrou and Itroun</em>; author of <em>Splintered Light</em>',
  },
  {
    name: 'William Fliss',
    description: 'Tolkien archivist at Marquette University’s Raynor Library',
  },
  {
    name: 'John Garth',
    description:
      'Author of <em>Tolkien and the Great War</em>, <em>The Worlds of J.R.R. Tolkien</em> and <em>Tolkien at Exeter College</em>',
  },
  {
    name: 'Christopher Gilson',
    description:
      'Chief editor of <em>Parma Eldalamberon</em> and leading member of the Elvish Linguistic Fellowship',
  },
  {
    name: 'Nick Groom',
    description: 'Author of <em>Twenty-First-Century Tolkien</em>',
  },
  {
    name: 'Peter Grybauskas',
    description:
      'Editor of <em>The Battle of Maldon: together with The Homecoming of Beorhtnoth</em>',
  },
  {
    name: 'Wayne G. Hammond',
    description:
      'Co-editor of <em>The Collected Poems of J.R.R. Tolkien</em>, <em>The Lord of the Rings</em>, <em>Roverandom</em>, co-author of <em>J.R.R. Tolkien: Artist and Illustrator</em>, <em>The Lord of the Rings: A Reader’s Companion</em>, <em>The J.R.R. Tolkien Companion and Guide</em>',
  },
  {
    name: 'Andrew Higgins',
    description: 'Co-editor of <em>A Secret Vice</em>',
  },
  {
    name: 'Thomas Honegger',
    description:
      'Co-editor of <em>Sub-creating Arda</em> and <em>Laughter in Middle-earth: Humour in and around the Works of J.R.R. Tolkien</em>',
  },
  {
    name: 'Carl F. Hostetter',
    description: 'Editor of <em>The Nature of Middle-earth</em> and <em>Vinyar Tengwar</em>',
  },
  {
    name: 'John Howe',
    description:
      'Artist who has illustrated covers for <em>The Hobbit</em>, <em>The Lord of the Rings</em>, and <em>The History of Middle-earth</em>',
  },
  {
    name: 'Yvette Kisor',
    description:
      'Researcher on medieval literature and the works of J.R.R. Tolkien, co-editor of <em>Tolkien Studies</em> and <em>Tolkien and Alterity</em>',
  },
  {
    name: 'Kristine Larsen',
    description: 'Writer and researcher on science and astronomy in Tolkien’s works',
  },
  {
    name: 'Alan Lee',
    description:
      'Artist who has illustrated <em>The Lord of the Rings</em>, <em>The Children of Húrin</em>, <em>Beren and Lúthien</em> and <em>The Fall of Númenor</em>',
  },
  {
    name: 'Ted Nasmith',
    description:
      'Artist who has illustrated <em>The Silmarillion</em> and <em>Unfinished Tales</em>',
  },
  {
    name: 'Richard Ovenden',
    description: 'Bodley’s Librarian and co-editor of <em>The Great Tales Never End</em>',
  },
  {
    name: 'John D. Rateliff',
    description: 'Author of <em>The History of The Hobbit</em>',
  },
  {
    name: 'Robin Anne Reid',
    description: 'Researcher on Tolkien fandom, fan fiction, and race in Tolkien’s works',
  },
  {
    name: 'Christina Scull',
    description:
      'Co-editor of <em>The Collected Poems of J.R.R. Tolkien</em>, <em>The Lord of the Rings</em>, <em>Roverandom</em>, co-author of <em>J.R.R. Tolkien: Artist and Illustrator</em>, <em>The Lord of the Rings: A Reader’s Companion</em>, <em>The J.R.R. Tolkien Companion and Guide</em>',
  },
  {
    name: 'Brian Sibley',
    description: 'Author of <em>The Fall of Númenor</em>',
  },
  {
    name: 'Chris Smith',
    description: 'The Tolkien editor of HarperCollins',
  },
  {
    name: 'James Tauber',
    description: 'Researcher on corpus linguistics and digital humanities for Tolkien’s works',
  },
  {
    name: 'Jessica Yates',
    description:
      'Writer of the introduction and commentaries to Joan Wyatt’s <em>A Middle-earth Album</em>, researcher into Tolkien’s sources',
  },
]

export default speakers
