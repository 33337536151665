import {
  zoomBreakoutSaturdayURL,
  zoomBreakoutSundayURL,
  zoomWebinarSaturdayURL,
  zoomWebinarSundayURL,
} from '../../constants.js'

const programme = {
  saturday: [
    {
      startTime: '11:00',
      duration: 15,
      title: '<em>Welcome and Introduction</em>',
      description: `All papers will be delivered in <a href="${zoomWebinarSaturdayURL}" target="_blank" rel="noreferrer">the Zoom webinar</a>`,
    },

    {
      startTime: '11:15',
      duration: 30,
      speaker: 'Richard Ovenden',
      title: 'Working with Christopher',
    },

    {
      startTime: '11:45',
      duration: 30,
      speaker: 'Chris Smith',
      title: 'A personal remembrance of Christopher',
    },

    {
      startTime: '12:15',
      duration: 30,
      speaker: 'Alan Lee',
      title: 'A Conversation: Working with Christopher and the Estate',
    },

    {
      startTime: '12:45',
      duration: 30,
      speaker: 'Vincent Ferré',
      title: 'A polyphonic tribute to Christopher Tolkien – from France',
    },

    {
      startTime: '13:15',
      duration: 45,
      title: '<em>Break</em>',
    },

    {
      startTime: '14:00',
      duration: 30,
      speaker: 'Thomas Honegger',
      title: 'Fathers and Sons, or: Looking for CJRT in JRRT’s Fiction',
    },

    {
      startTime: '14:30',
      duration: 30,
      speaker: 'Brian Sibley',
      title: '‘Pursuing it with eager feet’: My Indebtedness to Christopher',
    },

    {
      startTime: '15:00',
      duration: 30,
      speaker: 'Peter Grybauskas',
      title: '“Earth Under the Feet of Romance”: Hobbits and The Homecoming',
    },

    {
      startTime: '15:30',
      duration: 30,
      speaker: 'Denis Bridoux',
      title:
        '“It is dear to my heart”: An art-oriented recollection of a correspondence with Christopher Tolkien (1982-1988)',
    },

    {
      startTime: '16:00',
      duration: 60,
      title: '<em>Breakout Rooms</em>',
      description: `Use the break to chat with your fellow attendees in <a href="${zoomBreakoutSaturdayURL}" target="_blank" rel="noreferrer">a Zoom breakout room</a>`,
    },

    {
      startTime: '17:00',
      duration: 30,
      speaker: 'Christopher Gilson',
      title:
        'Christopher Tolkien’s involvement in the publication of his father’s linguistic writings in Parma Eldalamberon',
    },

    {
      startTime: '17:30',
      duration: 30,
      speaker: 'Carl F. Hostetter',
      title: 'Scans Across the Water: Editing Tolkien’s Manuscripts from 4,000 Miles Away',
    },

    {
      startTime: '18:00',
      duration: 30,
      speaker: 'Douglas A. Anderson',
      title: 'Christopher Tolkien as Medieval Scholar',
    },

    {
      startTime: '18:30',
      duration: 30,
      speaker: 'Ted Nasmith',
      title: 'Working With Christopher on Illustrating <em>The Silmarillion</em>',
    },

    {
      duration: 30,
      startTime: '19:00',
      title: '<em>Break</em>',
    },

    {
      startTime: '19:30',
      duration: 60,
      speaker:
        'Dimitra Fimi, Peter Grybauskas, Andrew Higgins & John D. Rateliff (hosted by Shaun Gunner)',
      title: 'Panel Discussion: Editing Tolkien',
    },

    {
      startTime: '20:30',
      duration: 30,
      speaker: 'William Fliss',
      title: 'A Fruitful Collaboration: Christopher Tolkien and Marquette University, 1974-2000',
    },

    {
      startTime: '21:00',
      duration: 30,
      speaker: 'John D. Rateliff',
      title: 'Editing <em>The Hobbit</em>',
    },

    {
      startTime: '21:30',
      duration: 5,
      title: 'Closing Comments',
    },
  ],
  sunday: [
    {
      startTime: '10:00',
      duration: 15,
      title: '<em>Welcome and Introduction</em>',
      description: `All papers will be delivered in <a href="${zoomWebinarSundayURL}" target="_blank" rel="noreferrer">the Zoom webinar</a>`,
    },

    {
      startTime: '10:15',
      duration: 30,
      speaker: 'John Howe',
      title:
        '"Wandering but not Lost" or another with a little wider view called "Where on Earth is Middle-earth?", about the geography of the imagination',
    },

    {
      startTime: '10:45',
      duration: 30,
      speaker: 'Nick Groom',
      title:
        'Forewords and Prefaces – or Apologies? Christopher Tolkien’s Prologues to <em>The History of Middle-earth</em>',
    },

    {
      startTime: '11:15',
      duration: 30,
      speaker: 'Sonali Chunodkar',
      title: 'Lost Tales and Found Myths: Christopher Tolkien on the ‘Mythology for England’',
    },

    {
      startTime: '11:45',
      duration: 30,
      speaker: 'Jessica Yates',
      title: 'Christopher Tolkien’s Lectures at Oxford',
    },

    {
      startTime: '12:15',
      duration: 45,
      title: '<em>Break</em>',
    },

    {
      startTime: '13:00',
      duration: 30,
      speaker: 'Sara Brown',
      title:
        '“We are as we are, and ye are as we find you” (Or Finrod Elfsplains the Gift of Ilúvatar): The ‘Athrabeth Finrod ah Andreth’ as Core Text in Tolkien’s Legendarium',
    },

    {
      startTime: '13:30',
      duration: 30,
      speaker: 'Kristine Larsen	',
      title: 'It’s so meta: CJRT on JRRT on JRRT in CJRT within the ‘Athrabeth Finrod ah Andreth’',
    },

    {
      startTime: '14:00',
      duration: 45,
      speaker: 'Christina Scull and Wayne G. Hammond',
      title: 'Working with Christopher: Art, Prose, and Poetry',
    },

    {
      startTime: '14:45',
      duration: 60,
      title: '<em>Breakout Rooms</em>',
      description: `Use the break to chat with your fellow attendees in <a href="${zoomBreakoutSundayURL}" target="_blank" rel="noreferrer">a Zoom breakout room</a>`,
    },

    {
      startTime: '15:45',
      duration: 30,
      speaker: 'Nicholas Birns',
      title: 'Christopher Tolkien and ‘the Goths and the Huns’',
    },

    {
      startTime: '16:15',
      duration: 30,
      speaker: 'Yvette Kisor	',
      title: 'Christopher Tolkien as Reader of His Father’s Work in “The Lay of Leithian”',
    },

    {
      startTime: '16:45',
      duration: 30,
      speaker: 'Robin Anne Reid',
      title:
        'Tolkien’s ‘Absent [Female] Characters’: How Christopher Tolkien Expanded Middle-earth',
    },

    {
      startTime: '17:15',
      duration: 60,
      title: '<em>Break</em>',
    },

    {
      startTime: '18:15',
      duration: 30,
      speaker: 'Michael D. C. Drout',
      title:
        '‘He builded better than he knew’: Christopher Tolkien’s <em>The History of The Lord of the Rings</em>',
    },

    {
      startTime: '18:45',
      duration: 30,
      speaker: 'James Tauber',
      title: 'Continuing Christopher Tolkien’s Work in a Digital Age',
    },

    {
      startTime: '19:15',
      duration: 30,
      speaker: 'Verlyn Flieger',
      title: '“Where Credit is Due”',
    },

    {
      startTime: '19:45',
      duration: 5,
      title: '<em>Closing Comments</em>',
    },
  ],
}

export default programme
