const Link = ({ link }) => {
  const { title, url, icon } = link

  return (
    <a href={url} target="_blank" rel="noreferrer">
      <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-start">
        <div>
          <h5 className="mb-0">{title}</h5>
        </div>
        <i className={`bi bi-${icon}`}></i>
      </li>
    </a>
  )
}

export default Link
